<template>
  <home></home>
</template>

<style lang="stylus">
#app{
  height 100%;
}
</style>
<script>
import Home from "./views/Home/home";
export default {
  components: {Home}
}
</script>
