import axios from "axios";
import baseUrl from "./baseUrl";
import router from "../router";
import qs from "qs";
const service = axios.create({
    timeout: 67000,
    baseURL: baseUrl.baseUrl,
});

service.interceptors.request.use(
    (config) => {
        // 判断Content-type是否存在headers里面，如果存在则使用存在的，如果不存在则判断请求是否为post请求，如果是post请求则使用x-www-form-urlencoded,如果不是post请求则使用application/json;charset=UTF-8
        if (!("Content-type" in config.headers)) {
            config.headers["Content-type"] = "application/json;charset=UTF-8";
            if (config.method === "post") {
                config.headers["Content-type"] = "application/x-www-form-urlencoded";
            }
        }
        config.headers.token = localStorage.getItem("token");
        config.headers.version = "1.0.1";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        const res = {};
        if (response.data.code === 401) {
            localStorage.setItem("token", "");
            router.replace({ path: "/login" });
            return "";
        } else {
            res.status = response.data.code;
            res.data = response.data.data;
            res.msg = response.data.msg;
            return Promise.resolve(res);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export function post(url, data = {}, header = {}) {
    const sendObj = {
        url: url,
        method: "post",
        headers: header,
        data: data,
    };
    sendObj.data = qs.stringify(sendObj.data, { arrayFormat: "repeat" });
    return service(sendObj);
}

export function postUpload(url, data) {
    const sendObj = {
        url: url,
        method: "post",
        headers: { "Content-type": "multipart/form-data" },
        data: data,
    };
    return service(sendObj);
}
export function get(url, data = {}, header = {}) {
    data.t = new Date().getTime();
    return service({
        url: url,
        method: "get",
        headers: header,
        params: data,
    });
}
