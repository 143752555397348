import {get} from "../request";
import linkList from "../apiList/linkList";

const List = () => {
    return get(linkList.List);
};

export default {
    List,
}
