import {get} from "../request";
import everyDayList from "../apiList/everyDayList";

const List = () => {
    return get(everyDayList.everyDayList);
}

export default {
    List,
}
