import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index/index"),
    meta: {
      title: "首页",
    }
  },
  {
    path: "/content/list/:id",
    name: "contentList",
    component: () => import("../views/Content/contentList"),
    meta: {
      title: "文章列表",
    }
  },
  {
    path: "/content/info/:id/:class",
    name: "contentInfo",
    component: () => import("../views/Content/info"),
    meta: {
      title: "文章详情"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})


router.beforeEach((to, from, next) => {
  if (to.meta.title !== "" && to.meta.title !== undefined) {
    window.document.title = "个人小站/星哥blog" + to.meta.title;
  }
  next();
});

export default router
