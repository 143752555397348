import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Ant from "view-ui-plus"
import "view-ui-plus/dist/styles/viewuiplus.css"
import moment from "moment";

const app = createApp(App);

app.use(store).use(router).use(Ant).use(moment).mount('#app')
