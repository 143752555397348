<template>
  <div class="layout">
    <Menu mode="horizontal" theme="primary" accordion>
      <MenuItem name="index" to="/">
        首页
      </MenuItem>
      <Submenu :name="index" v-for="(item, index) in classification" :key="index">
        <template #title>
          {{ item.name }}
        </template>
        <MenuItem name="item.id" :to="`/content/list/${item.id}`">{{ item.name }}</MenuItem>
        <MenuItem v-for="(childeItem, i) in item.childe" :key="i" name="childeItem.id" :to="`/content/list/${childeItem.id}`">{{ childeItem.name }}</MenuItem>
      </Submenu>
    </Menu>
    <Layout style="margin-left: 10px; margin-right: 10px;">
      <Content style="margin-top: 20px; height: 100%; margin-bottom: 20px;">
        <div class="everyDay" style="width: 20%">
          <div class="aBriefRemark">
            <h3>每日一言:</h3>
            <ul>
              <li v-for="(item, index) in everyDay.aBriefRemark" :key="index">
                <span>时间: {{ formatDate(item.created_at) }}</span>
                <span>{{ item.content }}</span>
              </li>
            </ul>
          </div>
          <div class="poisonChickenSoup">
            <h3>每日毒鸡汤:</h3>
            <ul>
              <li v-for="(item, index) in everyDay.poisonChickenSoup" :key="index">
                <span>时间: {{ formatDate(item.created_at) }}</span>
                <span>{{ item.content }}</span>
              </li>
            </ul>
          </div>
          <div class="loveTalk">
            <h3>每日情话&污话:</h3>
            <ul>
              <li v-for="(item, index) in everyDay.loveTalk" :key="index">
                <span>时间: {{ formatDate(item.created_at) }}</span>
                <span>{{ item.content }}</span>
              </li>
            </ul>
          </div>
        </div>
        <router-view />
        <div style="width: 20%; margin-left: 10px;">
          <h3>友情链接:</h3>
          <div class="link">
            <Tag size="medium" v-for="(item, index) in link" :key="index" @click="goto(item.url)">{{ item.name }}</Tag>
          </div>
          <div class="layout-footer">
            <a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备17036619号</a>
          </div>
        </div>
        <div class="extra-space"></div>
      </Content>

    </Layout>
  </div>
</template>

<script>
import classificationRequest from "../../request/apiRequest/classificationRequest";
import everyDayRequest from "../../request/apiRequest/everyDayRequest";
import moment from "moment";
import LinkRequest from "../../request/apiRequest/LinkRequest";
export default {
  name: "home",
  data() {
    return {
      items: [],
      classification: [],
      everyDay: [],
      link: []
    };
  },
  mounted() {
    this.getClassificationList();
    this.getEveryDay();
    this.getLink();
  },
  methods: {
    // 获取分类
    getClassificationList(){
      classificationRequest.ListTree().then((res) => {
        if (res.status === 0) {
          this.classification = res.data;
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    // 获取每日系列
    getEveryDay(){
      everyDayRequest.List().then((res) => {
        if (res.status === 0) {
          this.everyDay = res.data;
        }
      });
    },
    getLink(){
      LinkRequest.List().then((res) => {
        if (res.status === 0) {
          this.link = res.data;
        }
      });
    },
    formatDate(value) {
      const date = new Date(value);
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    goto(url) {
      window.open(url)
    }
  }
};
</script>

<style scoped>
.extra-space {
  height: 60px; /* 根据底部连接的高度调整 */
}
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow-x: hidden;
  height: 100%;
}
.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.dev-run-preview .dev-run-preview-edit {
  display: none;
}
.ivu-layout {
  height: 100%;
  display: flex;
  justify-content: center;
}
.ivu-layout-content {
  display: flex;
  justify-content: space-between;
}
.everyDay {
  width: 20%;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
}
.everyDay ul li{
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid #dbdbdb;
  padding-top: 10px;
  box-sizing: border-box;
}
.everyDay ul li span:last-child{
  text-indent: 2em;
}
.everyDay .poisonChickenSoup, .everyDay .loveTalk {
  margin-top: 10px;
}
.link {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 10px;
}
.ivu-tag {
  cursor:pointer;
}
.layout-footer{
  background: #7CBCE9;
  color: white;
  width: 100%;
  text-align: center;
}
</style>
