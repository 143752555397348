import {get} from "../request";
import classificationList from "../apiList/classificationList";

const ListTree = () => {
    return get(classificationList.List);
};

export default {
    ListTree,
};
